import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import EdgeChartColors from './chart_colors';

import {
  asyncChart,
  defaultNoLeftMenu,
  defaultNoLeftMenuWithAnot,
  defaultBarOptions,
  defaultChartConfiguration,
  defaultChartConfigurationDimension,
  defaultChartConfigurationDetails,
  defaultsFlexWork,
  defaultStackedBarChartConfiguration,
  defaultStackedBarChartPlusConfiguration,
  defaultLineChartConfiguration,
  setMaxmin,
} from './chart_types_helpers';


Chart.register(annotationPlugin);

sessionStorage.setItem('getOfflinePage', 'false');

let benchmarkChart;

export default {
  horizontal_bar(element, data, dataLabels, ticks) {
    return new Chart(element, defaultChartConfiguration(ticks, dataLabels, data));
  },

  key_drivers_std_horizontal_bar_noanot(element, data, dataLabels, ticks, showticks = true) {
    return new Chart(element, defaultNoLeftMenu(data, dataLabels, ticks, showticks));
  },

  key_drivers_horizontal_bar(element, data, dataLabels, ticks, showticks = true) {
    return new Chart(element, defaultNoLeftMenuWithAnot(data, dataLabels, ticks, showticks));
  },

  benchmark_chart(element, values, dataLabels, ticks) {
    const graphPay = element[0];
    const ctxPay = graphPay.getContext('2d');
    const gradientBG2 = ctxPay.createLinearGradient(0, 0, ctxPay.canvas.width / 2, 0);
    gradientBG2.addColorStop(1, '#00898C');
    gradientBG2.addColorStop(1, '#00898C');
    gradientBG2.addColorStop(1, '#00898C');
    gradientBG2.addColorStop(1, '#00898C');
    gradientBG2.addColorStop(1, '#00898C');
    return new Chart(ctxPay, {
      responsive: true,
      type: 'bar',
      data: {
        labels: ticks,
        datasets: [{
          data: values,
          backgroundColor: gradientBG2,
          borderColor: '#00898C',
        }],
      },
      options: {
        indexAxis: 'y',
        layout: {
          padding: {
            right: 44,
          },
        },
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              title() {
                return '';
              },
              label(context) {
                return `${parseFloat(context.formattedValue).toFixed(0)}%`;
              },
            },
          },
          legend: {
            display: false,
          },
          datalabels: {
            anchor: 'end',
            align: 'end',
            color: '#000',
            formatter(value) {
              return `${value.toFixed(0)}%`;
            },
            font: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 12,
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  },

  employee_rating_horizontal_bar(element, data, dataLabels, ticks) {
    return new Chart(element, defaultChartConfiguration(ticks, dataLabels, data, {
    }));
  },

  employee_rating_horizontal_bar_noanot(element, data, dataLabels, ticks) {
    return new Chart(element, defaultChartConfigurationDimension(ticks, dataLabels, data));
  },

  employee_rating_horizontal_bar_dimension(element, data, dataLabels, ticks, showTicks = false) {
    return new Chart(element, defaultChartConfigurationDetails(ticks, dataLabels, data, showTicks));
  },

  retentions_chart(element, data, dataLabels, ticks, bottomTrend) {
    const ydist = bottomTrend;
    return new Chart(element, {
      type: 'bar',
      data: {
        labels: ticks,
        datasets: [{
          backgroundColor: EdgeChartColors.red,
          data,
          label: dataLabels,
          barPercentage: 0.62,
          categoryPercentage: 1.0,
        }],
      },
      options: {
        animation: {
          onComplete: ({ chart: { canvas } }) => {
            const target = canvas.getAttribute('data-target');
            if (!target) {
              return;
            }
            setTimeout(() => {
              const image = new Image();
              image.src = canvas.toDataURL('image/png');
              document.querySelector(`#${target}`).appendChild(image);
              document.getElementById('myList').style.display = 'none';
            }, 0);
          },
        },
        layout: {
          padding: {
            top: 30,
          },
        },
        scales: {
          x: {
            display: true,
            ticks: {
              display: true,
              font: {
                family: 'opensans-semibold',
                weight: 'bold',
                size: 14,
              },
              color: '#000',
            },
            border: {
              display: false,
            },
          },
          y: {
            max: 100,
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
          },
        },
        plugins: {
          tooltip: {
            titleFont: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 16,
            },
            bodyFont: {
              family: 'opensans-regular',
              weight: 'normal',
              size: 16,
            },
            boxHeight: 14,
            callbacks: {
              label(context) {
                return `${parseFloat(context.formattedValue).toPrecision(3)}%`;
              },
            },
          },
          annotation: {
            annotations: {
              line1: {
                type: 'line',
                yMin: ydist,
                yMax: ydist,
                borderColor: '#750223',
                borderWidth: 2,
                borderDash: [8, 6],
              },
              label1: {
                type: 'label',
                xValue: -0.5,
                yValue: ydist,
                xAdjust: ctx => ctx.chart.chartArea.width,
                yAdjust: 10,
                backgroundColor: '#750223',
                position: 'end',
                color: '#fff',
                content: [`${ydist.toFixed(0)}%`],
                borderRadius: 17,
                padding: 6,
                font: {
                  family: 'opensans-bold',
                  weight: 'bold',
                  size: 12,
                },
              },
            },
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              font: {
                family: 'opensans-semibold',
                size: 14,
                weight: 'bold',
              },
              color: '#000',
              boxWidth: 14,
              boxHeight: 14,
            },
          },
          datalabels: {
            anchor: 'end',
            align: 'top',
            color: 'black',
            formatter(value) {
              return `${parseFloat(value).toFixed(1)}%`;
            },
            font: {
              family: 'opensans-semibold',
              size: 12,
              weight: 'bold',
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  },

  retentions_chart_noanot(element, data, dataLabels, ticks) {
    return new Chart(element, {
      type: 'bar',
      data: {
        labels: ticks,
        datasets: [{
          backgroundColor: EdgeChartColors.red,
          data,
          label: dataLabels,
          barPercentage: 0.62,
          categoryPercentage: 1.0,
        }],
      },
      options: {
        animation: {
          onComplete: ({ chart: { canvas } }) => {
            const target = canvas.getAttribute('data-target');
            if (!target) {
              return;
            }
            setTimeout(() => {
              const image = new Image();
              image.src = canvas.toDataURL('image/png');
              document.querySelector(`#${target}`).appendChild(image);
              document.getElementById('myList').style.display = 'none';
            }, 0);
          },
        },
        layout: {
          padding: {
            top: 30,
          },
        },
        scales: {
          x: {
            display: true,
            ticks: {
              display: true,
              font: {
                family: 'opensans-semibold',
                weight: 'bold',
                size: 14,
              },
              color: '#000',
            },
            border: {
              display: false,
            },
          },
          y: {
            max: 100,
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
          },
        },
        plugins: {
          tooltip: {
            titleFont: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 16,
            },
            bodyFont: {
              family: 'opensans-regular',
              weight: 'normal',
              size: 16,
            },
            boxHeight: 14,
            callbacks: {
              label(context) {
                return `${parseFloat(context.formattedValue).toPrecision(3)}%`;
              },
            },
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              font: {
                family: 'opensans-semibold',
                size: 14,
                weight: 'bold',
              },
              color: '#000',
              boxWidth: 14,
              boxHeight: 14,
            },
          },
          datalabels: {
            anchor: 'end',
            align: 'top',
            color: 'black',
            formatter(value) {
              return `${parseFloat(value).toFixed(1)}%`;
            },
            font: {
              family: 'opensans-semibold',
              size: 12,
              weight: 'bold',
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  },

  board_members_chart(element, data, _dataLabels, ticks) {
    const ydist = 30;
    return new Chart(element, {
      type: 'bar',
      data: {
        labels: ticks,
        datasets: [{
          data,
          backgroundColor: [EdgeChartColors.red, EdgeChartColors.turquoise_1],
          barPercentage: 0.62,
          categoryPercentage: 1.0,
        }],
      },
      options: {
        animation: {
          onComplete: ({ chart: { canvas } }) => {
            const target = canvas.getAttribute('data-target');
            if (!target) {
              return;
            }
            setTimeout(() => {
              const image = new Image();
              image.src = canvas.toDataURL('image/png');
              document.querySelector(`#${target}`).appendChild(image);
            }, 0);
          },
        },
        layout: {
          padding: {
            top: 30,
          },
        },
        scales: {
          x: {
            border: {
              display: false,
            },
            ticks: {
              display: true,
              font: {
                family: 'opensans-semibold',
                size: 14,
                weight: 'bold',
              },
              color: '#000',
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            display: false,
            max: 100,
          },
        },
        plugins: {
          tooltip: {
            titleFont: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 16,
            },
            bodyFont: {
              family: 'opensans-regular',
              weight: 'normal',
              size: 16,
            },
            boxHeight: 14,
            callbacks: {
              title(context) {
                return `${context[0].label.toString().replace(/,/g, ' ')}`;
              },
              label(context) {
                return `${parseFloat(context.formattedValue).toPrecision(3)}%`;
              },
            },
          },
          annotation: {
            annotations: {
              line1: {
                type: 'line',
                yMin: ydist,
                yMax: ydist,
                borderColor: '#750223',
                borderWidth: 2,
                borderDash: [8, 6],
              },
              label1: {
                type: 'label',
                xValue: -0.5,
                yValue: ydist,
                xAdjust: ctx => ctx.chart.chartArea.width,
                yAdjust: 10,
                backgroundColor: '#750223',
                position: 'end',
                color: '#fff',
                content: [`${ydist.toFixed(0)}%`],
                borderRadius: 17,
                padding: 6,
                font: {
                  family: 'opensans-bold',
                  weight: 'bold',
                  size: 12,
                },
              },
            },
          },
          legend: {
            display: false,
          },
          datalabels: {
            anchor: 'end',
            align: 'end',
            color: '#000',
            formatter(value) {
              return `${parseFloat(value).toFixed(1)}%`;
            },
            font: {
              family: 'opensans-semibold',
              size: 12,
              weight: 'bold',
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  },

  board_members_chart_noanot(element, data, _dataLabels, ticks) {
    return new Chart(element, {
      type: 'bar',
      data: {
        labels: ticks,
        datasets: [{
          data,
          backgroundColor: [EdgeChartColors.red, EdgeChartColors.turquoise_1],
          barPercentage: 0.62,
          categoryPercentage: 1.0,
        }],
      },
      options: {
        animation: {
          onComplete: ({ chart: { canvas } }) => {
            const target = canvas.getAttribute('data-target');
            if (!target) {
              return;
            }
            setTimeout(() => {
              const image = new Image();
              image.src = canvas.toDataURL('image/png');
              document.querySelector(`#${target}`).appendChild(image);
            }, 0);
          },
        },
        layout: {
          padding: {
            top: 30,
          },
        },
        scales: {
          x: {
            border: {
              display: false,
            },
            ticks: {
              display: true,
              font: {
                family: 'opensans-semibold',
                size: 14,
                weight: 'bold',
              },
              color: '#000',
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            display: false,
            max: 100,
          },
        },
        plugins: {
          tooltip: {
            titleFont: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 16,
            },
            bodyFont: {
              family: 'opensans-regular',
              weight: 'normal',
              size: 16,
            },
            boxHeight: 14,
            callbacks: {
              title(context) {
                return `${context[0].label.toString().replace(/,/g, ' ')}`;
              },
              label(context) {
                return `${parseFloat(context.formattedValue).toPrecision(3)}%`;
              },
            },
          },
          legend: {
            display: false,
          },
          datalabels: {
            anchor: 'end',
            align: 'end',
            color: '#000',
            formatter(value) {
              return `${parseFloat(value).toFixed(1)}%`;
            },
            font: {
              family: 'opensans-semibold',
              size: 12,
              weight: 'bold',
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  },

  vertical_bar_stack_plus_dimension(element, data, dataLabels, ticks) {
    Chart.defaults.scale.grid.display = false;
    Chart.defaults.scale.ticks.display = false;
    const colors = [EdgeChartColors.maroon1, EdgeChartColors.normalGrey, EdgeChartColors.darkBlue,
      EdgeChartColors.annotations.red, EdgeChartColors.zorbaBrown, EdgeChartColors.whaleBlue,
      EdgeChartColors.curiousBlue, EdgeChartColors.genoaGreen, EdgeChartColors.plumViolet,
      EdgeChartColors.porticaYellow, EdgeChartColors.spunpearlBlue, EdgeChartColors.charmRed,
      EdgeChartColors.tealGreen, EdgeChartColors.springGreen, EdgeChartColors.danubeBlue,
      EdgeChartColors.chocolateRed, EdgeChartColors.endeavourBlue, EdgeChartColors.cloudYellow,
      EdgeChartColors.payneGrey, EdgeChartColors.moonYellow, EdgeChartColors.scarletRed,
      EdgeChartColors.fuchsiaViolet, EdgeChartColors.russianBlack, EdgeChartColors.seaPink,
      EdgeChartColors.lightbBlue, EdgeChartColors.purple, EdgeChartColors.monteCarlo, EdgeChartColors.mondoBrown];

    return new Chart(element, {
      type: 'bar',
      data: {
        labels: ticks.map(tick => tick.split(' ')),
        datasets: dataLabels.map((label, i) => ({
          data: data[i],
          backgroundColor: (i === dataLabels.length - 1) ? EdgeChartColors.e_blue : colors[i],
          label,
          barPercentage: 0.5,
          categoryPercentage: 1,
        })),
      },
      options: {
        animation: {
          onComplete: ({ chart: { canvas } }) => {
            const target = canvas.getAttribute('data-target');
            if (!target) {
              return;
            }

            setTimeout(() => {
              const image = new Image();

              image.src = canvas.toDataURL('image/png');
              document.querySelector(`#${target}`).appendChild(image);
            }, 0);
          },
        },
        layout: {
          padding: {
            top: 40,
          },
        },
        scales: {
          x: {
            border: {
              display: false,
            },
            display: true,
            stacked: true,
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: true,
              font: {
                family: 'opensans-semibold',
                weight: 'bold',
                size: 14,
              },
              color: '#000',
            },
          },
          y: {
            display: false,
            max: 100,
            stacked: true,
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: true,
              font: {
                family: 'opensans-semibold',
                weight: 'bold',
                size: 14,
              },
              color: '#000',
            },
          },
        },
        plugins: {
          tooltip: {
            titleFont: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 16,
            },
            bodyFont: {
              family: 'opensans-regular',
              weight: 'normal',
              size: 16,
            },
            boxHeight: 14,
            callbacks: {
              title(context) {
                return `${context[0].label.toString().replace(/,/g, ' ')}`;
              },
              label(context) {
                if (context.dataset.label === 'No employees') {
                  return 'No of employee: 0%';
                }

                if (context.dataset.label.length > 35) {
                  const getFirstPart = context.dataset.label.slice(0, 32);
                  return `${getFirstPart.padEnd(35, '.')} ${parseFloat(context.formattedValue).toPrecision(3)}%`;
                }

                return `${context.dataset.label} ${parseFloat(context.formattedValue).toPrecision(3)}%`;
              },
            },
          },
          legend: {
            display: false,
            position: 'bottom',
            labels: {
              font: {
                family: 'opensans-semibold',
                size: 14,
                weight: 'bold',
              },
              color: '#000',
              boxWidth: 14,
              padding: 20,
            },
          },
          datalabels: {
            anchor: 'end',
            align: 'start',
            formatter(value, context) {
              if (value >= 10 && value <= 100 && context.dataset.label !== 'No employees') {
                return `${parseFloat(value).toFixed(1)}%`;
              }
              if (value === 100) {
                return '0.0%';
              }

              return '';
            },
            font: {
              family: 'opensans-semibold',
              size: 12,
              weight: 'bold',
            },
            color: '#fff',
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  },

  vertical_bar_stack(element, data, dataLabels, ticks) {
    Chart.defaults.scale.grid.display = false;
    Chart.defaults.scale.ticks.display = false;
    const colors = [EdgeChartColors.red, EdgeChartColors.turquoise_1, EdgeChartColors.e_blue, EdgeChartColors.gray];
    return new Chart(element, {
      type: 'bar',
      data: {
        labels: ticks.map(tick => tick.split(' ')),
        datasets: dataLabels.map((label, i) => ({
          data: data[i],
          backgroundColor: colors[i],
          label,
          barPercentage: 0.4,
          categoryPercentage: 1,
        })),
      },
      options: {
        animation: {
          onComplete: ({ chart: { canvas } }) => {
            const target = canvas.getAttribute('data-target');
            if (!target) {
              return;
            }
            setTimeout(() => {
              const image = new Image();
              image.src = canvas.toDataURL('image/png');
              document.querySelector(`#${target}`).appendChild(image);
            }, 0);
          },
        },
        layout: {
          padding: {
            top: 40,
          },
        },
        scales: {
          x: {
            display: true,
            stacked: true,
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: true,
              font: {
                family: 'opensans-semibold',
                weight: 'bold',
                size: 14,
              },
              color: '#000',
            },
            border: {
              display: false,
            },
          },
          y: {
            display: false,
            max: 100,
            stacked: true,
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: true,
              font: {
                family: 'opensans-semibold',
                weight: 'bold',
                size: 14,
              },
              color: '#000',
            },
          },
        },
        plugins: {
          tooltip: {
            titleFont: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 16,
            },
            bodyFont: {
              family: 'opensans-regular',
              weight: 'normal',
              size: 16,
            },
            boxHeight: 14,
            callbacks: {
              title(context) {
                return `${context[0].label.toString().replace(/,/g, ' ')}`;
              },
              label(context) {
                if (context.dataset.label === 'No employees' && parseInt(context.formattedValue, 10) === 100) {
                  return 'No of employee 0%';
                }

                if (context.dataset.label === 'Not applicable' && parseInt(context.formattedValue, 10) === 100) {
                  return 'Not applicable';
                }

                return `${context.dataset.label}: ${parseFloat(context.formattedValue).toPrecision(3)}%`;
              },
            },
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              font: {
                family: 'opensans-semibold',
                size: 14,
                weight: 'bold',
              },
              color: '#000',
              boxWidth: 14,
              padding: 20,
            },
          },
          datalabels: {
            anchor: 'end',
            align: 'start',
            formatter(value, context) {
              if (value > 0 && value <= 100) {
                if (value === 100 && context.dataset.label === 'No employees') {
                  return '0.0%';
                }
                if (context.dataset.label === 'Not applicable') {
                  return (value === 100) ? 'NA' : '';
                }
                if (value >= 12) {
                  return `${parseFloat(value).toFixed(1)}%`;
                }
              }
              return '';
            },
            font: {
              family: 'opensans-semibold',
              size: 12,
              weight: 'bold',
            },
            color: '#fff',
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  },

  stacked_bar_chart(element, data, dataLabels, ticks, barPercentage, categoryPercentage) {
    return new Chart(element, defaultStackedBarChartConfiguration(data, dataLabels, ticks,
      barPercentage, categoryPercentage));
  },

  stacked_bar_chart_plus(element, data, dataLabels, ticks, barPercentage, categoryPercentage) {
    return new Chart(element, defaultStackedBarChartPlusConfiguration(data, dataLabels, ticks,
      barPercentage, categoryPercentage));
  },

  unexplained_gender_pay_gap(element, data, dataLabels, ticks) {
    const [baseSalary, cashBenefits, annot] = data;
    const ydist1 = annot;
    const ydist2 = -annot;

    return new Chart(element, {
      type: 'bar',
      data: {
        labels: ticks,
        datasets: [{
          backgroundColor: [EdgeChartColors.e_blue, EdgeChartColors.e_blue],
          data: [baseSalary, cashBenefits],
          barPercentage: 0.62,
          categoryPercentage: 1.0,
        }],
      },
      options: {
        animation: {
          onComplete: ({ chart: { canvas } }) => {
            const target = canvas.getAttribute('data-target');
            if (!target) {
              return;
            }
            setTimeout(() => {
              const image = new Image();
              image.src = canvas.toDataURL('image/png');
              document.querySelector(`#${target}`).appendChild(image);
            }, 0);
          },
        },
        clip: false,
        layout: {
          padding: {
            top: 60,
            bottom: 40,
          },
        },
        scales: {
          x: {
            border: {
              display: false,
            },
            display: true,
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: true,
              font: {
                family: 'opensans-semibold',
                weight: 'bold',
                size: 14,
              },
              color: '#000',
              padding: 20,
            },
            afterFit(scale) {
              scale.height = 70;
            },
          },
          y: {
            display: false,
          },
        },
        plugins: {
          tooltip: {
            titleFont: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 16,
            },
            bodyFont: {
              family: 'opensans-regular',
              weight: 'normal',
              size: 16,
            },
            boxHeight: 14,
            callbacks: {
              title(context) {
                return `${context[0].label.toString().replace(/,/g, ' ')}`;
              },
              label(context) {
                return `${parseFloat(context.formattedValue).toPrecision(3)}%`;
              },
            },
          },
          annotation: {
            annotations: {
              line1: {
                type: 'line',
                yMin: ydist1,
                yMax: ydist1,
                borderColor: '#750223',
                borderWidth: 2,
                borderDash: [8, 6],
              },
              label1: {
                type: 'label',
                drawTime: 'afterDraw',
                xValue: -0.5,
                xAdjust: ctx => ctx.chart.chartArea.width,
                yValue: ydist1,
                yAdjust: (ctx) => {
                  if (ctx.chart.config._config.data.datasets[0].data[0] < 0) return 10;

                  return 10;
                },
                backgroundColor: '#750223',
                position: 'end',
                color: '#fff',
                content: [`${ydist1}%`],
                borderRadius: 17,
                padding: 6,
                font: {
                  family: 'opensans-bold',
                  weight: 'bold',
                  size: 12,
                },
              },
              line2: {
                type: 'line',
                yMin: ydist2,
                yMax: ydist2,
                borderColor: '#750223',
                borderWidth: 2,
                borderDash: [8, 6],
              },
              label2: {
                type: 'label',
                drawTime: 'afterDraw',
                xValue: -0.5,
                xAdjust: ctx => ctx.chart.chartArea.width,
                yValue: ydist2,
                yAdjust: (ctx) => {
                  if (ctx.chart.config._config.data.datasets[0].data[0] < 0) return 10;

                  return 10;
                },
                backgroundColor: '#750223',
                position: 'end',
                color: '#fff',
                content: [`${ydist2}%`],
                borderRadius: 17,
                padding: 6,
                font: {
                  family: 'opensans-bold',
                  weight: 'bold',
                  size: 12,
                },
              },
            },
          },
          legend: {
            display: false,
          },
          datalabels: {
            anchor(context) {
              const value = context.dataset.data[context.dataIndex];
              return value < 0 ? 'start' : 'end';
            },
            align(context) {
              const value = context.dataset.data[context.dataIndex];
              return value < 0 ? 'start' : 'end';
            },
            color: '#000',
            formatter(value) {
              return `${parseFloat(value).toFixed(1)}%`;
            },
            font: {
              weight: 'bold',
              size: 12,
              family: 'opensans-semibold',
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  },
  unexplained_pay_gap(element, data, dataLabels, ticks) {
    const [baseSalary, cashBenefits] = data;

    let yRange = (Math.abs(baseSalary) > Math.abs(cashBenefits)) ? Math.abs(baseSalary) : Math.abs(cashBenefits);
    yRange = (yRange < 5) ? 5 : yRange;

    return new Chart(element, {
      type: 'bar',
      data: {
        labels: ticks,
        datasets: [{
          backgroundColor: [EdgeChartColors.e_blue, EdgeChartColors.e_blue],
          data: [baseSalary, cashBenefits],
          barPercentage: 0.62,
          categoryPercentage: 1.0,
        }],
      },
      options: {
        animation: {
          onComplete: ({ chart: { canvas } }) => {
            const target = canvas.getAttribute('data-target');
            if (!target) {
              return;
            }
            setTimeout(() => {
              const image = new Image();
              image.src = canvas.toDataURL('image/png');
              document.querySelector(`#${target}`).appendChild(image);
            }, 0);
          },
        },
        layout: {
          padding: {
            top: 30,
            bottom: 24,
          },
        },
        scales: {
          x: {
            border: {
              display: false,
            },
            display: true,
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: true,
              font: {
                family: 'opensans-semibold',
                weight: 'bold',
                size: 14,
              },
              color: '#000',
              padding: 20,
            },
            afterFit(scale) {
              scale.height = 70;
            },
          },
          y: {
            display: false,
            min: -yRange,
            max: yRange,
          },
        },
        plugins: {
          tooltip: {
            titleFont: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 16,
            },
            bodyFont: {
              family: 'opensans-regular',
              weight: 'normal',
              size: 16,
            },
            boxHeight: 14,
            callbacks: {
              title(context) {
                return `${context[0].label.toString().replace(/,/g, ' ')}`;
              },
              label(context) {
                return `${parseFloat(context.formattedValue).toPrecision(3)}%`;
              },
            },
          },
          legend: {
            display: false,
          },
          datalabels: {
            anchor(context) {
              const value = context.dataset.data[context.dataIndex];
              return value < 0 ? 'start' : 'end';
            },
            align(context) {
              const value = context.dataset.data[context.dataIndex];
              return value < 0 ? 'start' : 'end';
            },
            color: '#000',
            formatter(value) {
              return `${parseFloat(value).toFixed(1)}%`;
            },
            font: {
              weight: 'bold',
              size: 12,
              family: 'opensans-semibold',
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  },

  flexible_workplace_charts(element, data, dataLabels, ticks) {
    const colors = [EdgeChartColors.red, EdgeChartColors.turquoise_1, EdgeChartColors.red, EdgeChartColors.blue];
    const legendMargin = {
      id: 'legendMargin',
      beforeInit(chart) {
        const fitValue = chart.legend.fit;
        chart.legend.fit = function fit() {
          fitValue.bind(chart.legend)();
          const height = this.height + 30;
          return height;
        };
      },
    };
    return new Chart(element, {
      type: 'bar',
      data: {
        labels: ticks.map(tick => tick.split(' ')),
        datasets: dataLabels.map((label, i) => ({
          backgroundColor: colors[i],
          data: data[i],
          label,
          barPercentage: 0.8,
          categoryPercentage: 0.8,
        })),
      },
      options: {
        animation: {
          onComplete: ({ chart: { canvas } }) => {
            const target = canvas.getAttribute('data-target');
            if (!target) {
              return;
            }
            setTimeout(() => {
              const image = new Image();
              image.src = canvas.toDataURL('image/png');
              document.querySelector(`#${target}`).appendChild(image);
            }, 0);
          },
        },
        layout: {
          padding: {
            top: 40,
          },
        },
        hover: { mode: null },
        scales: {
          x: {
            border: {
              display: false,
            },
            display: true,
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: true,
              font: {
                family: 'opensans-semibold',
                weight: 'bold',
                size: 14,
              },
              color: '#000',
            },
          },
          y: {
            display: false,
            max: 100,
            grid: {
              display: false,
              drawBorder: false,
            },
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
            align: 'center',
            labels: {
              font: {
                family: 'opensans-semibold',
                weight: 'bold',
                size: 14,
              },
              boxWidth: 14,
              color: '#000',
            },
          },
          tooltip: {
            titleFont: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 16,
            },
            bodyFont: {
              family: 'opensans-regular',
              weight: 'normal',
              size: 16,
            },
            boxHeight: 14,
            callbacks: {
              title(context) {
                return `${context[0].label.toString().replace(/,/g, ' ')}`;
              },
              label(context) {
                return `${context.dataset.label} ${parseFloat(context.formattedValue).toPrecision(3)}%`;
              },
            },
          },
          datalabels: {
            anchor: 'end',
            align: 'end',
            formatter(value, context) {
              if ((data[2] !== undefined) && (data[2][context.dataIndex] === 100)) {
                return 'NA';
              }
              return `${parseFloat(value).toFixed(1)}%`;
            },
            font: {
              family: 'opensans-semibold',
              size: 12,
              weight: 'bold',
            },
            color: '#000',
          },
        },
      },
      plugins: [ChartDataLabels, legendMargin],
    });
  },

  flexible_work(element, data, dataLabels, ticks) {
    return new Chart(element, defaultsFlexWork(data, dataLabels, ticks));
  },

  avg_pay_dif_chart(element, data, dataLabels, ticks) {
    const max = Math.round(setMaxmin(data)[1]);
    const min = Math.round(setMaxmin(data)[0]);
    const colors = ['#FBC22F', '#FF6600'];

    return new Chart(element, {
      type: 'bar',
      data: {
        labels: ticks.map(tick => tick.split(' ')),
        datasets: dataLabels.map((label, i) => ({
          data: data[i],
          backgroundColor: colors[i],
          label,
        })),
      },
      options: defaultBarOptions({
        scales: {
          xAxes: [{
            ticks: {
              maxRotation: 0,
            },
          }],
          yAxes: [{
            ticks: {
              min,
              max,
            },
          }],
        },
      }, null),
    });
  },

  career_transitions_chart_pie(element, { data }, dataLabels, ticks, noPercent = '%') {
    // the chart is built clockwise, therefore men need to come first in order to have women on the left hand side
    const customLegend = {
      id: 'customLegend',
      beforeInit(chart) {
        const htmlOpenTag = '<ul>';
        const htmlCloseTag = '</ul>';
        let resultAllLiTag = '';
        for (let i = 0; i < chart.config._config.data.datasets[0].data.length; i += 1) {
          resultAllLiTag += `<li style="font-family: 'opensans-semibold'; font-size: 13px;  padding-bottom: 10px;">
          <span style="display: inline-block; width:12px; height: 12px; vertical-align: middle; background-color:
          ${chart.config._config.data.datasets[0].backgroundColor[i]}"></span>
          &nbsp;${chart.config._config.data.labels[i]}</li>`;
        }
        const listAllLegends = htmlOpenTag + resultAllLiTag + htmlCloseTag;
        document.getElementById(`${chart.canvas.id}_legend`).innerHTML = listAllLegends;
      },
    };

    return new Chart(element, {
      type: 'pie',
      data: {
        labels: dataLabels,
        datasets: [{
          backgroundColor: [EdgeChartColors.maroon, EdgeChartColors.normalGrey,
            EdgeChartColors.darkBlue, EdgeChartColors.blue],
          data,
        }],
      },
      options: {
        borderWidth: 0,
        animation: {
          onComplete: ({ chart: { canvas } }) => {
            const target = canvas.getAttribute('data-target');
            if (!target) {
              return;
            }

            setTimeout(() => {
              const image = new Image();

              image.src = canvas.toDataURL('image/png');
              document.querySelector(`#${target}`).appendChild(image);
            }, 0);
          },
        },
        layout: {
          padding: 35,
        },
        hover: { mode: null },
        plugins: {
          tooltip: {
            titleFont: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 16,
            },
            bodyFont: {
              family: 'opensans-regular',
              weight: 'normal',
              size: 16,
            },
            callbacks: {
              title() {
                return '';
              },
              label(context) {
                return `${parseFloat(context.formattedValue).toPrecision(3)}${noPercent}`;
              },
            },
          },
          legend: {
            display: false,
            maxHeight: 800,
            labels: {
              boxWidth: 10,
              boxHeight: 10,
              padding: 20,
              color: '#000',
              font: {
                size: 14,
                weight: 600,
                family: 'opensans-semibold',
              },
            },
          },
          datalabels: {
            anchor: 'end',
            align: 'end',
            offset: -2,
            formatter(value) {
              if (value >= 4) {
                return `${parseFloat(value).toFixed(1)}${noPercent}`;
              }

              return '';
            },
            font: {
              size: 11,
              weight: 600,
              family: 'opensans-semibold',
            },
            color: '#000',
          },
        },
      },
      plugins: [ChartDataLabels, customLegend],
    });
  },

  custom_filter_chart_pie(element, { data }, dataLabels, ticks, noPercent = '%') {
    // the chart is built clockwise, therefore men need to come first in order to have women on the left hand side
    const customLegend = {
      id: 'customLegend',
      beforeInit(chart) {
        const htmlOpenTag = '<ul>';
        const htmlCloseTag = '</ul>';
        let resultAllLiTag = '';
        for (let i = 0; i < chart.config._config.data.datasets[0].data.length; i += 1) {
          resultAllLiTag += `<li style="font-family: 'opensans-semibold'; font-size: 13px;  padding-bottom: 10px;">
          <span style="display: inline-block; width:12px; height: 12px; vertical-align: middle; background-color:
          ${chart.config._config.data.datasets[0].backgroundColor[i]}"></span>
          &nbsp;${chart.config._config.data.labels[i]}</li>`;
        }
        const listAllLegends = htmlOpenTag + resultAllLiTag + htmlCloseTag;
        document.getElementById(`${chart.canvas.id}_legend`).innerHTML = listAllLegends;
      },
    };

    return new Chart(element, {
      type: 'pie',
      data: {
        labels: dataLabels,
        datasets: [{
          backgroundColor: [EdgeChartColors.maroon1, EdgeChartColors.normalGrey, EdgeChartColors.darkBlue,
            EdgeChartColors.annotations.red, EdgeChartColors.zorbaBrown, EdgeChartColors.whaleBlue,
            EdgeChartColors.curiousBlue, EdgeChartColors.genoaGreen, EdgeChartColors.plumViolet,
            EdgeChartColors.porticaYellow, EdgeChartColors.spunpearlBlue, EdgeChartColors.charmRed,
            EdgeChartColors.tealGreen, EdgeChartColors.springGreen, EdgeChartColors.danubeBlue,
            EdgeChartColors.chocolateRed, EdgeChartColors.endeavourBlue, EdgeChartColors.cloudYellow,
            EdgeChartColors.payneGrey, EdgeChartColors.moonYellow, EdgeChartColors.scarletRed,
            EdgeChartColors.fuchsiaViolet, EdgeChartColors.russianBlack, EdgeChartColors.seaPink,
            EdgeChartColors.lightbBlue, EdgeChartColors.purple, EdgeChartColors.monteCarlo, EdgeChartColors.mondoBrown],
          data,
        }],
      },
      options: {
        borderWidth: 0,
        animation: {
          onComplete: ({ chart: { canvas } }) => {
            const target = canvas.getAttribute('data-target');
            if (!target) {
              return;
            }

            setTimeout(() => {
              const image = new Image();

              image.src = canvas.toDataURL('image/png');
              document.querySelector(`#${target}`).appendChild(image);
            }, 0);
          },
        },
        layout: {
          padding: 35,
        },
        hover: { mode: null },
        plugins: {
          tooltip: {
            titleFont: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 16,
            },
            bodyFont: {
              family: 'opensans-regular',
              weight: 'normal',
              size: 16,
            },
            callbacks: {
              title() {
                return '';
              },
              label(context) {
                return `${parseFloat(context.formattedValue).toPrecision(3)}${noPercent}`;
              },
            },
          },
          legend: {
            display: false,
            maxHeight: 800,
            labels: {
              boxWidth: 10,
              boxHeight: 10,
              padding: 20,
              color: '#000',
              font: {
                size: 14,
                weight: 600,
                family: 'opensans-semibold',
              },
            },
          },
          datalabels: {
            anchor: 'end',
            align: 'end',
            offset: -2,
            formatter(value) {
              if (value >= 4) {
                return `${parseFloat(value).toFixed(1)}${noPercent}`;
              }

              return '';
            },
            font: {
              size: 11,
              weight: 600,
              family: 'opensans-semibold',
            },
            color: '#000',
          },
        },
      },
      plugins: [ChartDataLabels, customLegend],
    });
  },

  plus_career_transitions_chart_sy(element, { data }, dataLabels, ticks, noPercent = '%') {
    // the chart is built clockwise, therefore men need to come first in order to have women on the left hand side
    return new Chart(element, {
      type: 'pie',
      data: {
        labels: dataLabels.reverse(),
        datasets: [{
          backgroundColor: [
            EdgeChartColors.turquoise_1,
            EdgeChartColors.turquoise_2,
            EdgeChartColors.red,
            EdgeChartColors.red1,
            EdgeChartColors.perception.p6,
            EdgeChartColors.perception.p5,
            EdgeChartColors.lightGray],
          data,
        }],
      },
      options: {
        animation: {
          onComplete: ({ chart: { canvas } }) => {
            const target = canvas.getAttribute('data-target');
            if (!target) {
              return;
            }
            setTimeout(() => {
              const image = new Image();
              image.src = canvas.toDataURL('image/png');
              document.querySelector(`#${target}`).appendChild(image);
            }, 0);
          },
        },
        layout: {
          padding: {
            left: 35,
            right: 35,
          },
        },
        hover: { mode: null },
        plugins: {
          tooltip: {
            titleFont: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 16,
            },
            bodyFont: {
              family: 'opensans-regular',
              weight: 'normal',
              size: 16,
            },
            callbacks: {
              title() {
                return '';
              },
              label(context) {
                return `${parseFloat(context.formattedValue).toPrecision(3)} ${noPercent}`;
              },
            },
          },
          legend: {
            display: false,
          },
          datalabels: {
            anchor: 'end',
            align: 'end',
            offset: -2,
            formatter(value) {
              if (value >= 4) {
                return `${parseFloat(value).toFixed(1)}${noPercent}`;
              }

              return '';
            },
            font: {
              size: 12,
              weight: 600,
              family: 'opensans-semibold',
            },
            color: '#000',
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  },

  career_transitions_chart(element, { data }, dataLabels, ticks, noPercent = '%') {
    // the chart is built clockwise, therefore men need to come first in order to have women on the left hand side
    let colors = [EdgeChartColors.turquoise_1, EdgeChartColors.red];
    if (dataLabels.length > 2) {
      colors = [EdgeChartColors.perception.p6, EdgeChartColors.turquoise_1, EdgeChartColors.red];
    }
    data = data.reverse();
    return new Chart(element, {
      type: 'pie',
      data: {
        labels: dataLabels.reverse(),
        datasets: [{
          backgroundColor: colors,
          data,
        }],
      },
      options: {
        animation: {
          duration: Chart.defaults.offscreenRendering ? 0 : 1000,
          onComplete: ({ chart: { canvas } }) => {
            const target = canvas.getAttribute('data-target');
            if (!target) {
              return;
            }
            setTimeout(() => {
              const image = new Image();
              image.src = canvas.toDataURL('image/png');
              document.querySelector(`#${target}`).appendChild(image);
            }, 0);
          },
        },
        layout: {
          padding: {
            left: 35,
            right: 35,
          },
        },
        hover: { mode: null },
        plugins: {
          tooltip: {
            titleFont: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 16,
            },
            bodyFont: {
              family: 'opensans-regular',
              weight: 'normal',
              size: 16,
            },
            callbacks: {
              title: () => null,
              label(context) {
                if (`${noPercent}` === '%') {
                  return `${parseFloat(context.formattedValue).toPrecision(3)} ${noPercent}`;
                }

                return `${parseFloat(context.formattedValue)}`;
              },
            },
          },
          legend: {
            display: false,
          },
          datalabels: {
            anchor: 'end',
            align: 'end',
            offset: -2,
            formatter(value) {
              if (`${noPercent}` !== '%') {
                return `${value.toFixed(0)}${noPercent}`;
              }
              if (dataLabels.length > 2) {
                if (value >= 4) {
                  return `${parseFloat(value).toFixed(1)}${noPercent}`;
                }

                return '';
              }
              if (value > 0) {
                return `${parseFloat(value).toFixed(1)}${noPercent}`;
              }

              return '';
            },
            font: {
              size: 11,
              weight: 600,
              family: 'opensans-SemiBold',
            },
            color: '#000',
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  },

  plus_career_transitions_chart(element, { data }, dataLabels, ticks, noPercent = '%') {
    return new Chart(element, {
      type: 'pie',
      data: {
        labels: dataLabels.reverse(),
        datasets: [{
          backgroundColor: [
            EdgeChartColors.turquoise_1,
            EdgeChartColors.turquoise_2,
            EdgeChartColors.red,
            EdgeChartColors.red1,
            EdgeChartColors.lightGray,
          ],
          data,
        }],
      },
      options: {
        layout: {
          padding: {
            left: 35,
            right: 35,
          },
        },
        hover: {
          mode: null,
        },
        plugins: {
          tooltip: {
            titleFont: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 16,
            },
            bodyFont: {
              family: 'opensans-regular',
              weight: 'normal',
              size: 16,
            },
            callbacks: {
              title: () => null,
              label(context) {
                if (`${noPercent}` === '%') {
                  return `${parseFloat(context.formattedValue).toPrecision(3)} ${noPercent}`;
                }

                return `${parseFloat(context.formattedValue)}`;
              },
            },
          },
          legend: {
            display: false,
          },
          datalabels: {
            anchor: 'end',
            align: 'end',
            offset: -1,
            formatter(value) {
              if (`${noPercent}` !== '%') {
                return `${value.toFixed(0)}${noPercent}`;
              }
              if (value >= 4) {
                return `${parseFloat(value).toFixed(1)}${noPercent}`;
              }

              return '';
            },
            font: {
              size: 11,
              weight: 600,
              family: 'opensans-SemiBold',
            },
            color: '#000',
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  },

  mentoring_chart(element, data, dataLabels, ticks) {
    const colors = [EdgeChartColors.red, EdgeChartColors.turquoise_1];
    const legendMargin = {
      id: 'legendMargin',
      beforeInit(chart) {
        const fitValue = chart.legend.fit;
        chart.legend.fit = function fit() {
          fitValue.bind(chart.legend)();
          const height = this.height + 30;
          return height;
        };
      },
    };

    return new Chart(element, {
      type: 'bar',
      data: {
        labels: ticks.map(tick => tick.split(' ')),
        datasets: dataLabels.map((label, i) => ({
          backgroundColor: colors[i],
          label,
          data: data[i],
          barPercentage: 0.8,
          categoryPercentage: 0.8,
        })),
      },
      options: {
        animation: {
          onComplete: ({ chart: { canvas } }) => {
            const target = canvas.getAttribute('data-target');
            if (!target) {
              return;
            }
            setTimeout(() => {
              const image = new Image();
              image.src = canvas.toDataURL('image/png');
              document.querySelector(`#${target}`).appendChild(image);
            }, 0);
          },
        },
        layout: {
          padding: {
            top: 40,
          },
        },
        scales: {
          x: {
            border: {
              display: false,
            },
            display: true,
            ticks: {
              display: true,
              font: {
                family: 'opensans-semibold',
                weight: 'bold',
                size: 14,
              },
              color: '#000',
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            display: false,
            grid: {
              display: false,
              drawBorder: false,
            },
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              font: {
                family: 'opensans-semibold',
                weight: 'bold',
                size: 14,
              },
              boxWidth: 14,
              color: '#000',
            },
          },
          tooltip: {
            titleFont: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 16,
            },
            bodyFont: {
              family: 'opensans-regular',
              weight: 'normal',
              size: 16,
            },
            boxHeight: 14,
            callbacks: {
              title(context) {
                return `${context[0].label.toString().replace(/,/g, ' ')}`;
              },
            },
          },
          datalabels: {
            anchor: 'end',
            align: 'end',
            formatter(value, context) {
              if ((data[2] !== undefined) && (data[2][context.dataIndex] === 100)) {
                return 'NA';
              }
              return `${value.toFixed(0)}`;
            },
            font: {
              family: 'opensans-semibold',
              size: 12,
              weight: 'bold',
            },
            color: '#000',
          },
        },
      },
      plugins: [ChartDataLabels, legendMargin],
    });
  },

  maternity_retention_rate_chart(element, data, dataLabels, ticks, percent = '%') {
    const yArray = data;
    const yArraySorted = [...yArray].sort((a, b) => b - a);

    const ydist = yArraySorted[0] * 80 / 100;
    return new Chart(element, {
      type: 'bar',
      data: {
        labels: ticks,
        datasets: [{
          backgroundColor: EdgeChartColors.red,
          data,
          barPercentage: 0.6,
          categoryPercentage: 1.0,
        }],
      },
      options: {
        animation: {
          onComplete: ({ chart: { canvas } }) => {
            const target = canvas.getAttribute('data-target');
            if (!target) {
              return;
            }
            setTimeout(() => {
              const image = new Image();
              image.src = canvas.toDataURL('image/png');
              document.querySelector(`#${target}`).appendChild(image);
            }, 0);
          },
        },
        clip: false,
        layout: {
          padding: {
            top: 40,
          },
        },
        hover: { mode: null },
        scales: {
          x: {
            border: {
              display: false,
            },
            display: true,
            ticks: {
              display: true,
              font: {
                family: 'opensans-semibold',
                size: 14,
                weight: 'bold',
              },
              color: '#000',
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            display: false,
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          annotation: {
            annotations: {
              line1: {
                type: 'line',
                yMin: ydist,
                yMax: ydist,
                borderColor: '#750223',
                borderWidth: 2,
                borderDash: [8, 6],
              },
              label1: {
                type: 'label',
                xValue: -0.5,
                yValue: ydist,
                xAdjust: ctx => ctx.chart.chartArea.width,
                yAdjust: 10,
                backgroundColor: '#750223',
                position: 'end',
                color: '#fff',
                content: ['80%'],
                borderRadius: 17,
                padding: 6,
                font: {
                  family: 'opensans-bold',
                  weight: 'bold',
                  size: 12,
                },
              },
            },
          },
          tooltip: {
            yAlign: 'bottom',
            titleFont: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 16,
            },
            bodyFont: {
              family: 'opensans-regular',
              weight: 'normal',
              size: 16,
            },
            boxHeight: 14,
            callbacks: {
              title(context) {
                if (context[0].label.length > 35) {
                  const getFirstPart = context[0].label.slice(0, 32);
                  return getFirstPart.padEnd(35, '.');
                }

                return '';
              },
            },
          },
          legend: {
            display: false,
          },
          datalabels: {
            anchor: 'end',
            align: 'end',
            color: 'black',
            formatter(value) {
              return `${value.toFixed(0)} ${percent}`;
            },
            font: {
              family: 'opensans-semibold',
              size: 12,
              weight: 'bold',
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  },

  paternity_comparison_chart(element, data, dataLabels, ticks, percent = '%') {
    return new Chart(element, {
      type: 'bar',
      data: {
        labels: ticks,
        datasets: [{
          backgroundColor: EdgeChartColors.turquoise_1,
          data,
          barPercentage: 0.84,
          categoryPercentage: 0.74,
        }],
      },
      options: {
        animation: {
          onComplete: ({ chart: { canvas } }) => {
            const target = canvas.getAttribute('data-target');
            if (!target) {
              return;
            }
            setTimeout(() => {
              const image = new Image();
              image.src = canvas.toDataURL('image/png');
              document.querySelector(`#${target}`).appendChild(image);
            }, 0);
          },
        },
        layout: {
          padding: {
            top: 40,
          },
        },
        scales: {
          x: {
            border: {
              display: false,
            },
            display: true,
            ticks: {
              display: true,
              font: {
                family: 'opensans-semibold',
                size: 14,
                weight: 'bold',
              },
              color: '#000',
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            display: false,
            grid: {
              display: false,
              drawBorder: false,
            },
          },
        },
        plugins: {
          tooltip: {
            titleFont: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 16,
            },
            bodyFont: {
              family: 'opensans-regular',
              weight: 'normal',
              size: 16,
            },
            boxHeight: 14,
            callbacks: {
              title(context) {
                return `${context[0].label.toString().replace(/,/g, ' ')}`;
              },
            },
          },
          legend: {
            display: false,
          },
          datalabels: {
            anchor: 'end',
            align: 'end',
            formatter(value) {
              if (value > 0) {
                return `${value.toFixed(0)} ${percent}`;
              }

              return '';
            },
            font: {
              family: 'opensans-semibold',
              size: 12,
              weight: 'bold',
            },
            color: '#000',
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  },

  parental_leave_duration_chart(element, data, dataLabels, ticks, percent = '%') {
    const colors = [EdgeChartColors.red, EdgeChartColors.turquoise_1];
    const legendMargin = {
      id: 'legendMargin',
      beforeInit(chart) {
        const fitValue = chart.legend.fit;
        chart.legend.fit = function fit() {
          fitValue.bind(chart.legend)();
          const height = this.height + 10;
          return height;
        };
      },
    };

    return new Chart(element, {
      type: 'bar',
      data: {
        labels: ticks,
        datasets: dataLabels.map((label, i) => ({
          backgroundColor: colors[i],
          data: data[i],
          label,
          barPercentage: 0.84,
          categoryPercentage: 0.74,
        })),
      },
      options: {
        animation: {
          onComplete: ({ chart: { canvas } }) => {
            const target = canvas.getAttribute('data-target');
            if (!target) {
              return;
            }
            setTimeout(() => {
              const image = new Image();
              image.src = canvas.toDataURL('image/png');
              document.querySelector(`#${target}`).appendChild(image);
            }, 0);
          },
        },
        layout: {
          padding: {
            top: 40,
          },
        },
        hover: { mode: null },
        scales: {
          x: {
            border: {
              display: false,
            },
            display: true,
            ticks: {
              display: true,
              font: {
                family: 'opensans-semibold',
                size: 14,
                weight: 'bold',
              },
              color: '#000',
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            display: false,
            grid: {
              display: false,
              drawBorder: false,
            },
          },
        },
        plugins: {
          tooltip: {
            titleFont: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 16,
            },
            bodyFont: {
              family: 'opensans-regular',
              weight: 'normal',
              size: 16,
            },
            boxHeight: 14,
          },
          legend: {
            position: 'bottom',
            labels: {
              font: {
                family: 'opensans-semibold',
                size: 14,
                weight: 'bold',
              },
              color: '#000',
              padding: 30,
              boxWidth: 14,
            },
          },
          datalabels: {
            anchor: 'end',
            align: 'end',
            formatter(value) {
              return `${value.toFixed(0)} ${percent}`;
            },
            font: {
              family: 'opensans-semibold',
              size: 12,
              weight: 'bold',
            },
            color: '#000',
          },
        },
      },
      plugins: [ChartDataLabels, legendMargin],
    });
  },

  performance_rating_chart(element, data, dataLabels, ticks) {
    const colors = [EdgeChartColors.e_blue, EdgeChartColors.turquoise_1, EdgeChartColors.turquoise_2,
      EdgeChartColors.gray];

    return new Chart(element, {
      type: 'bar',
      data: {
        labels: ticks,
        datasets: dataLabels.map((label, i) => ({
          backgroundColor: colors[i],
          data: data[i],
          label,
          barPercentage: 0.78,
          categoryPercentage: 0.9,
        })),
      },
      options: {
        interaction: {
          mode: 'index',
        },
        animation: {
          onComplete: ({ chart: { canvas } }) => {
            const target = canvas.getAttribute('data-target');
            if (!target) {
              return;
            }
            setTimeout(() => {
              const image = new Image();
              image.src = canvas.toDataURL('image/png');
              document.querySelector(`#${target}`).appendChild(image);
            }, 0);
          },
        },
        indexAxis: 'y',
        layout: {
          padding: {
            top: 0,
          },
        },
        scales: {
          x: {
            max: 100,
            stacked: true,
            display: false,
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            border: {
              display: false,
            },
            stacked: true,
            ticks: {
              padding: 35,
              crossAlign: 'far',
              display: true,
              font: {
                family: 'opensans-semibold',
                size: 16,
                weight: 'bold',
              },
              color: '#000',
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
        },
        hover: { mode: null },
        plugins: {
          tooltip: {
            titleFont: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 16,
            },
            bodyFont: {
              family: 'opensans-regular',
              weight: 'normal',
              size: 16,
            },
            callbacks: {
              label(context) {
                if (context.dataset.label === 'Not applicable') {
                  return (parseInt(context.formattedValue, 10) === 100) ? 'Not applicable' : '';
                }
                if (context.dataset.label !== 'Not applicable' && (data[3] !== undefined)
                && (data[3][context.dataIndex] === 100)) {
                  return '';
                }
                return `${context.dataset.label}: ${parseFloat(context.formattedValue).toFixed(1)}%`;
              },
            },
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              boxWidth: 14,
              padding: 20,
              font: {
                family: 'opensans-semibold',
                size: 14,
                weight: 'bold',
              },
              color: '#000',
            },
          },
          datalabels: {
            anchor: 'end',
            align: 'start',
            formatter(value, context) {
              if (value > 0) {
                if (context.dataset.label === 'Not applicable') {
                  return (value === 100) ? 'NA' : '';
                }
                if (value >= 5) {
                  return `${parseFloat(value).toFixed(1)}%`;
                }
              }
              return '';
            },
            font: {
              family: 'opensans-semibold',
              size: 10,
              weight: 'bold',
            },
            color: '#fff',
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  },

  perception_chart(element, data, dataLabels, ticks, showTicks = false, key = '') {
    Chart.defaults.scale.grid.display = false;
    Chart.defaults.scale.ticks.display = false;
    let colors = [EdgeChartColors.red, EdgeChartColors.lightred];

    if (key === 'female') {
      colors = [EdgeChartColors.perception.p1, EdgeChartColors.perception.p2];
    } else if (key === 'male') {
      colors = [EdgeChartColors.perception.p3, EdgeChartColors.perception.p4];
    } else if (key === 'non-binary') {
      colors = [EdgeChartColors.perception.p5, EdgeChartColors.perception.p6];
    }
    return new Chart(element, {
      type: 'bar',
      data: {
        labels: ticks,
        datasets: dataLabels.map((label, i) => (
          {
            backgroundColor: colors[i],
            data: data[i],
            label,
            barPercentage: 1,
            categoryPercentage: 0.8,
          })),
      },
      options: {
        responsive: false,
        maintainAspectRatio: false,
        aspectRatio: 1,
        indexAxis: 'y',
        scales: {
          x: {
            display: false,
            grid: {
              display: false,
            },
          },
          y: {
            stacked: true,
            border: {
              display: false,
            },
            afterFit(scale) {
              if (showTicks === true) {
                scale.width = 80;
              } else {
                scale.paddingTop = 16;
                scale.paddingBottom = 16;
              }
            },
            grid: {
              drawBorder: false,
              display: false,
            },
            ticks: {
              display: showTicks,
              crossAlign: 'far',
              font: {
                family: 'opensans-semibold',
                size: 10,
                weight: 'bold',
              },
              color: '#000',
              padding: 4,
            },
          },
        },
        layout: {
          padding: {
            top: 20,
            right: 50,
          },
        },
        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
          datalabels: {
            anchor: 'end',
            align: 'end',
            color: '#000',
            formatter(value, context) {
              if (context.dataset.backgroundColor === '#c72858' || context.dataset.backgroundColor === '#219498'
            || context.dataset.backgroundColor === '#84d9d9') {
                return '';
              }

              return `${parseFloat(value).toFixed(1)}%`;
            },
            font: {
              family: 'opensans-semibold',
              size: 12,
              weight: 'bold',
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  },

  representation_requirement_std_chart(element, data, dataLabels, ticks) {
    const colors = [EdgeChartColors.red, EdgeChartColors.turquoise_1, EdgeChartColors.gray];

    return new Chart(element, {
      type: 'bar',
      data: {
        labels: ticks,
        datasets: dataLabels.map((label, i) => ({
          backgroundColor: colors[i],
          data: data[i],
          label,
          barThickness: 30,
        })),
      },
      options: {
        interaction: {
          mode: 'index',
        },
        animation: {
          onComplete: ({ chart: { canvas } }) => {
            const target = canvas.getAttribute('data-target');
            if (!target) {
              return;
            }
            setTimeout(() => {
              const image = new Image();
              image.src = canvas.toDataURL('image/png');
              document.querySelector(`#${target}`).appendChild(image);
            }, 0);
          },
        },
        indexAxis: 'y',
        scales: {
          x: {
            stacked: true,
            display: false,
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            border: {
              display: false,
            },
            stacked: true,
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: true,
              crossAlign: 'far',
              padding: 10,
              font: {
                family: 'opensans-semibold',
                size: 14,
                weight: 'bold',
              },
              color: '#000',
            },
          },
        },
        clip: false,
        layout: {
          padding: {
            top: 40,
            right: 40,
          },
        },
        plugins: {
          tooltip: {
            titleFont: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 16,
            },
            bodyFont: {
              family: 'opensans-regular',
              weight: 'normal',
              size: 16,
            },
            callbacks: {
              label(context) {
                if (context.dataset.label === 'Not applicable') {
                  return (parseInt(context.formattedValue, 10) === 100) ? 'Not applicable' : '';
                }
                if (context.dataset.label !== 'Not applicable' && (data[2] !== undefined)
                && (data[2][context.dataIndex] === 100)) {
                  return '';
                }

                return `${context.dataset.label}  ${parseFloat(context.formattedValue).toPrecision(3)}%`;
              },
            },
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              boxWidth: 14,
              boxHeight: 14,
              font: {
                family: 'opensans-semibold',
                weight: 'bold',
                size: 14,
              },
            },
          },
          annotation: {
            annotations: {
              line1: {
                type: 'line',
                xMin: 30,
                xMax: 30,
                borderColor: '#750223',
                borderWidth: 2,
                borderDash: [8, 6],
              },
              label1: {
                type: 'label',
                drawTime: 'afterDraw',
                xValue: 30,
                yValue: 0,
                yAdjust: -20,
                xAdjust: 20,
                backgroundColor: '#750223',
                position: 'end',
                color: '#fff',
                content: [`${30}%`],
                borderRadius: 17,
                padding: 6,
                font: {
                  family: 'opensans-bold',
                  weight: 'bold',
                  size: 12,
                },
              },
              line2: {
                type: 'line',
                xMin: 70,
                xMax: 70,
                borderColor: '#005d60',
                borderWidth: 2,
                borderDash: [8, 6],
              },
              label2: {
                type: 'label',
                drawTime: 'afterDraw',
                xValue: 70,
                yValue: 0,
                yAdjust: -20,
                xAdjust: 20,
                backgroundColor: '#005d60',
                position: 'end',
                color: '#fff',
                content: [`${30}%`],
                borderRadius: 17,
                padding: 6,
                font: {
                  family: 'opensans-semibold',
                  weight: 'bold',
                  size: 12,
                },
              },
            },
          },
          datalabels: {
            anchor: 'end',
            align: 'start',
            color: '#fff',
            formatter(value, context) {
              if (context.dataset.label === 'Not applicable') {
                return (value === 100) ? 'NA' : '';
              }
              if (value >= 14) {
                return `${parseFloat(value).toFixed(1)}%`;
              }
              return ' ';
            },
            font: {
              family: 'opensans-semibold',
              size: 12,
              weight: 'bold',
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  },

  representation_requirement_std_chart_noanot(element, data, dataLabels, ticks) {
    const colors = [EdgeChartColors.red, EdgeChartColors.turquoise_1, EdgeChartColors.gray];

    return new Chart(element, {
      type: 'bar',
      data: {
        labels: ticks,
        datasets: dataLabels.map((label, i) => ({
          backgroundColor: colors[i],
          data: data[i],
          label,
          barThickness: 30,
        })),
      },
      options: {
        interaction: {
          mode: 'index',
        },
        animation: {
          onComplete: ({ chart: { canvas } }) => {
            const target = canvas.getAttribute('data-target');
            if (!target) {
              return;
            }
            setTimeout(() => {
              const image = new Image();
              image.src = canvas.toDataURL('image/png');
              document.querySelector(`#${target}`).appendChild(image);
              document.getElementById('myList2').style.display = 'none';
            }, 0);
          },
        },
        indexAxis: 'y',
        scales: {
          x: {
            stacked: true,
            display: false,
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            border: {
              display: false,
            },
            stacked: true,
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: true,
              crossAlign: 'far',
              padding: 10,
              font: {
                family: 'opensans-semibold',
                size: 14,
                weight: 'bold',
              },
              color: '#000',
            },
          },
        },
        plugins: {
          tooltip: {
            titleFont: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 16,
            },
            bodyFont: {
              family: 'opensans-regular',
              weight: 'normal',
              size: 16,
            },
            boxHeight: 14,
            callbacks: {
              label(context) {
                if (context.dataset.label === 'Not applicable') {
                  return (parseInt(context.formattedValue, 10) === 100) ? 'Not applicable' : '';
                }
                if (context.dataset.label !== 'Not applicable' && (data[2] !== undefined)
                && (data[2][context.dataIndex] === 100)) {
                  return '';
                }

                return `${context.dataset.label}: ${parseFloat(context.formattedValue).toPrecision(3)}%`;
              },
            },
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              font: {
                family: 'opensans-semibold',
                weight: 'bold',
                size: 14,
              },
              boxWidth: 12,
              boxHeight: 12,
              color: '#000',
            },
          },
          datalabels: {
            anchor: 'end',
            align: 'start',
            color: '#fff',
            formatter(value, context) {
              if (value > 0 && value <= 100) {
                if (context.dataset.label === 'Not applicable') {
                  return (value === 100) ? 'NA' : '';
                }
                if (value >= 14) {
                  return `${parseFloat(value).toFixed(1)}%`;
                }
              }

              return '';
            },
            font: {
              family: 'opensans-semibold',
              size: 12,
              weight: 'bold',
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  },

  bench_mark_chart_dow_jones(element, data, dataLabels, ticks) {
    if (benchmarkChart) {
      benchmarkChart.destroy();
    }
    const barGrowth = {
      id: 'barGrowth',
      afterDatasetDraw(chart) {
        const { ctx, scales: { x, y } } = chart;
        for (let a = 0; a < chart.config._config.data.datasets[0].data.length; a += 1) {
          ctx.beginPath();
          ctx.lineWidth = 2;
          ctx.beginPath();
          ctx.moveTo(x.getPixelForValue(a), y.getPixelForValue(chart.config._config.data.datasets[0].data[a][0]));
          ctx.lineTo(x.getPixelForValue(a), y.getPixelForValue(chart.config._config.data.datasets[0].data[a][2]) + 10);
          ctx.strokeStyle = '#b1b1b1';
          ctx.stroke();
          ctx.fill();
          ctx.restore();
        }

        for (let a = 0; a < chart.config._config.data.datasets[0].data.length; a += 1) {
          ctx.beginPath();
          ctx.moveTo(x.getPixelForValue(a), y.getPixelForValue(chart.config._config.data.datasets[0].data[a][0]) - 6);
          ctx.lineTo(x.getPixelForValue(a) - 6,
            y.getPixelForValue(chart.config._config.data.datasets[0].data[a][0]) + 2);
          ctx.lineTo(x.getPixelForValue(a) + 6,
            y.getPixelForValue(chart.config._config.data.datasets[0].data[a][0]) + 2);
          ctx.moveTo(x.getPixelForValue(a), y.getPixelForValue(chart.config._config.data.datasets[0].data[a][0]) + 8);
          ctx.lineTo(x.getPixelForValue(a) - 6,
            y.getPixelForValue(chart.config._config.data.datasets[0].data[a][0]) + 2);
          ctx.lineTo(x.getPixelForValue(a) + 6,
            y.getPixelForValue(chart.config._config.data.datasets[0].data[a][0]) + 2);
          ctx.fillStyle = '#b1b1b1';
          ctx.fill();
          ctx.restore();
          ctx.font = '12px opensans-regular';
          ctx.fillStyle = '#000';
          ctx.textAlign = 'center';
          ctx.fillText(`${chart.config._config.data.datasets[0].data[a][0]}%`, x.getPixelForValue(a) + 40,
            y.getPixelForValue(chart.config._config.data.datasets[0].data[a][0]) + 1);
        }

        for (let a = 0; a < chart.config._config.data.datasets[0].data.length; a += 1) {
          ctx.beginPath();
          ctx.moveTo(x.getPixelForValue(a), y.getPixelForValue(chart.config._config.data.datasets[0].data[a][2]) - 2);
          ctx.lineTo(x.getPixelForValue(a) - 6,
            y.getPixelForValue(chart.config._config.data.datasets[0].data[a][2]) + 4);
          ctx.lineTo(x.getPixelForValue(a) + 6,
            y.getPixelForValue(chart.config._config.data.datasets[0].data[a][2]) + 4);
          ctx.moveTo(x.getPixelForValue(a), y.getPixelForValue(chart.config._config.data.datasets[0].data[a][2]) + 10);
          ctx.lineTo(x.getPixelForValue(a) - 6,
            y.getPixelForValue(chart.config._config.data.datasets[0].data[a][2]) + 4);
          ctx.lineTo(x.getPixelForValue(a) + 6,
            y.getPixelForValue(chart.config._config.data.datasets[0].data[a][2]) + 4);
          ctx.fillStyle = '#b1b1b1';
          ctx.fill();
          ctx.restore();
          ctx.font = '12px opensans-regular';
          ctx.fillStyle = '#000';
          ctx.textAlign = 'center';
          ctx.fillText(`${chart.config._config.data.datasets[0].data[a][2]}%`, x.getPixelForValue(a) + 40,
            y.getPixelForValue(chart.config._config.data.datasets[0].data[a][2]) + 10);
        }

        for (let a = 0; a < chart.config._config.data.datasets[0].data.length; a += 1) {
          ctx.beginPath();
          ctx.arc(x.getPixelForValue(a),
            y.getPixelForValue(chart.config._config.data.datasets[0].data[a][1]), 6, 0, 2 * Math.PI);
          ctx.fillStyle = '#595959';
          ctx.fill();
          ctx.restore();
          ctx.font = '12px opensans-regular';
          ctx.fillStyle = '#000';
          ctx.textAlign = 'center';
          ctx.fillText(`${chart.config._config.data.datasets[0].data[a][1]}%`,
            x.getPixelForValue(a) + 40, y.getPixelForValue(chart.config._config.data.datasets[0].data[a][1]) + 10);
        }
      },
    };

    benchmarkChart = new Chart(element, {
      type: 'bar',
      data: {
        labels: ticks,
        datasets: [
          {
            data: data[1],
            order: 1,
            categoryPercentage: 0.05,
            backgroundColor: 'grey',
          },
          {
            data: data[0],
            backgroundColor: '#AC0235',
            categoryPercentage: 0.28,
            order: 0,
          },
        ],
      },
      options: {
        animation: {
          onComplete: ({ chart: { canvas } }) => {
            const target = canvas.getAttribute('data-target');
            if (!target) {
              return;
            }
            setTimeout(() => {
              const image = new Image();
              image.src = canvas.toDataURL('image/png');
              document.querySelector(`#${target}`).appendChild(image);
            }, 4000);
          },
        },
        layout: {
          padding: 20,
        },
        x: {
          stacked: true,
          ticks: {
            display: true,
            font: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 14,
            },
          },
        },
        scales: {
          x: {
            border: {
              display: false,
            },
            display: true,
            ticks: {
              display: true,
              color: '#000',
              font: {
                size: 14,
              },
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            border: {
              display: false,
            },
            max: 100,
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: false,
            },
          },
        },
        plugins: {
          tooltip: {
            filter(tooltipItem) {
              return tooltipItem.datasetIndex === 1;
            },
            callbacks: {
              title(context) {
                return `${context[0].label.toString().replace(/,/g, ' ')}`;
              },
              label(context) {
                return `${parseFloat(context.formattedValue).toPrecision(3)}%`;
              },
            },
            titleFont: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 16,
            },
            bodyFont: {
              family: 'opensans-regular',
              weight: 'normal',
              size: 16,
            },
          },
          datalabels: {
            anchor: 'end',
            align: 'left',
            color: '#000',
            offset: 24,
            formatter(value, context) {
              if (context.dataset.backgroundColor === '#AC0235') {
                return `${parseFloat(value).toFixed(1)}%`;
              }

              return '';
            },
            font: {
              family: 'opensans-semibold',
              size: 12,
              weight: 'bold',
            },
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              padding: 20,
              font: {
                family: 'opensans-medium',
                size: 10,
                weight: 500,
              },
              color: '#000',
              usePointStyle: true,
              generateLabels() {
                const labels = [
                  {
                    label: dataLabels[0],
                    pointStyle: 'rect',
                    size: 1,
                    color: EdgeChartColors.red,
                  },
                  {
                    label: dataLabels[1],
                    pointStyle: 'rectRot',
                    size: 1,
                    color: EdgeChartColors.lightGray,
                  },
                  {
                    label: dataLabels[2],
                    pointStyle: 'circle',
                    size: 1,
                    color: EdgeChartColors.darkGray,
                  },
                  {
                    label: dataLabels[3],
                    pointStyle: 'rectRot',
                    size: 1,
                    color: EdgeChartColors.lightGray,
                  },
                ];
                return labels.map((label, index) => ({
                  text: label.label,
                  pointStyle: label.pointStyle,
                  strokeStyle: label.color,
                  fillStyle: label.color,
                  lineWidth: label.size,
                  index,
                }));
              },
            },
          },
        },
      },
      plugins: [ChartDataLabels, barGrowth],
    });
    return benchmarkChart;
  },

  bench_mark_chart_dow_jones_report(element, data, dataLabels, ticks) {
    const barGrowthIndustry = {
      id: 'barGrowthIndustry',
      afterDatasetDraw(chart) {
        const { ctx, scales: { x, y } } = chart;
        for (let a = 0; a < chart.config._config.data.datasets[0].data.length; a += 1) {
          ctx.beginPath();
          ctx.lineWidth = 2;
          ctx.beginPath();
          ctx.moveTo(x.getPixelForValue(a), y.getPixelForValue(chart.config._config.data.datasets[0].data[a][0]));
          ctx.lineTo(x.getPixelForValue(a), y.getPixelForValue(chart.config._config.data.datasets[0].data[a][2]) + 10);
          ctx.strokeStyle = '#b1b1b1';
          ctx.stroke();
          ctx.fill();
          ctx.restore();
        }

        for (let a = 0; a < chart.config._config.data.datasets[0].data.length; a += 1) {
          ctx.beginPath();
          ctx.moveTo(x.getPixelForValue(a), y.getPixelForValue(chart.config._config.data.datasets[0].data[a][0]) - 6);
          ctx.lineTo(x.getPixelForValue(a) - 6,
            y.getPixelForValue(chart.config._config.data.datasets[0].data[a][0]) + 2);
          ctx.lineTo(x.getPixelForValue(a) + 6,
            y.getPixelForValue(chart.config._config.data.datasets[0].data[a][0]) + 2);
          ctx.moveTo(x.getPixelForValue(a), y.getPixelForValue(chart.config._config.data.datasets[0].data[a][0]) + 8);
          ctx.lineTo(x.getPixelForValue(a) - 6,
            y.getPixelForValue(chart.config._config.data.datasets[0].data[a][0]) + 2);
          ctx.lineTo(x.getPixelForValue(a) + 6,
            y.getPixelForValue(chart.config._config.data.datasets[0].data[a][0]) + 2);
          ctx.fillStyle = '#b1b1b1';
          ctx.fill();
          ctx.restore();
          ctx.font = '12px opensans-regular';
          ctx.fillStyle = '#000';
          ctx.textAlign = 'center';
          ctx.fillText(`${chart.config._config.data.datasets[0].data[a][0]}%`, x.getPixelForValue(a) + 40,
            y.getPixelForValue(chart.config._config.data.datasets[0].data[a][0]) + 1);
        }

        for (let a = 0; a < chart.config._config.data.datasets[0].data.length; a += 1) {
          ctx.beginPath();
          ctx.moveTo(x.getPixelForValue(a), y.getPixelForValue(chart.config._config.data.datasets[0].data[a][2]) - 2);
          ctx.lineTo(x.getPixelForValue(a) - 6,
            y.getPixelForValue(chart.config._config.data.datasets[0].data[a][2]) + 4);
          ctx.lineTo(x.getPixelForValue(a) + 6,
            y.getPixelForValue(chart.config._config.data.datasets[0].data[a][2]) + 4);
          ctx.moveTo(x.getPixelForValue(a), y.getPixelForValue(chart.config._config.data.datasets[0].data[a][2]) + 10);
          ctx.lineTo(x.getPixelForValue(a) - 6,
            y.getPixelForValue(chart.config._config.data.datasets[0].data[a][2]) + 4);
          ctx.lineTo(x.getPixelForValue(a) + 6,
            y.getPixelForValue(chart.config._config.data.datasets[0].data[a][2]) + 4);
          ctx.fillStyle = '#b1b1b1';
          ctx.fill();
          ctx.restore();
          ctx.font = '12px opensans-regular';
          ctx.fillStyle = '#000';
          ctx.textAlign = 'center';
          ctx.fillText(`${chart.config._config.data.datasets[0].data[a][2]}%`, x.getPixelForValue(a) + 40,
            y.getPixelForValue(chart.config._config.data.datasets[0].data[a][2]) + 10);
        }

        for (let a = 0; a < chart.config._config.data.datasets[0].data.length; a += 1) {
          ctx.beginPath();
          ctx.arc(x.getPixelForValue(a),
            y.getPixelForValue(chart.config._config.data.datasets[0].data[a][1]), 6, 0, 2 * Math.PI);
          ctx.fillStyle = '#595959';
          ctx.fill();
          ctx.restore();
          ctx.font = '12px opensans-regular';
          ctx.fillStyle = '#000';
          ctx.textAlign = 'center';
          ctx.fillText(`${chart.config._config.data.datasets[0].data[a][1]}%`,
            x.getPixelForValue(a) + 40, y.getPixelForValue(chart.config._config.data.datasets[0].data[a][1]) + 10);
        }
      },
    };

    benchmarkChart = new Chart(element, {
      type: 'bar',
      data: {
        labels: ticks,
        datasets: [
          {
            data: data[1],
            order: 1,
            categoryPercentage: 0.05,
            backgroundColor: 'grey',
          },
          {
            data: data[0],
            backgroundColor: '#AC0235',
            categoryPercentage: 0.28,
            order: 0,
          },
        ],
      },
      options: {
        animation: {
          onComplete: ({ chart: { canvas } }) => {
            const target = canvas.getAttribute('data-target');
            if (!target) {
              return;
            }
            setTimeout(() => {
              const image = new Image();
              image.src = canvas.toDataURL('image/png');
              document.querySelector(`#${target}`).appendChild(image);
            }, 0);
          },
        },
        layout: {
          padding: 20,
        },
        x: {
          stacked: true,
          ticks: {
            display: true,
            font: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 14,
            },
          },
        },
        scales: {
          x: {
            border: {
              display: false,
            },
            display: true,
            ticks: {
              display: true,
              color: '#000',
              font: {
                size: 14,
              },
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            border: {
              display: false,
            },
            max: 100,
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: false,
            },
          },
        },
        plugins: {
          tooltip: {
            filter(tooltipItem) {
              return tooltipItem.datasetIndex === 1;
            },
            callbacks: {
              label(context) {
                return `${parseFloat(context.formattedValue).toPrecision(3)}%`;
              },
            },
            titleFont: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 16,
            },
            bodyFont: {
              family: 'opensans-regular',
              weight: 'normal',
              size: 16,
            },
          },
          datalabels: {
            anchor: 'end',
            align: 'left',
            color: '#000',
            offset: 24,
            formatter(value, context) {
              if (context.dataset.backgroundColor === '#AC0235') {
                return `${parseFloat(value).toFixed(1)}%`;
              }

              return '';
            },
            font: {
              family: 'opensans-semibold',
              size: 12,
              weight: 'bold',
            },
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              padding: 20,
              font: {
                family: 'opensans-medium',
                size: 10,
                weight: 500,
              },
              color: '#000',
              usePointStyle: true,
              generateLabels() {
                const labels = [
                  {
                    label: dataLabels[0],
                    pointStyle: 'rect',
                    size: 1,
                    color: EdgeChartColors.red,
                  },
                  {
                    label: dataLabels[1],
                    pointStyle: 'rectRot',
                    size: 1,
                    color: EdgeChartColors.lightGray,
                  },
                  {
                    label: dataLabels[2],
                    pointStyle: 'circle',
                    size: 1,
                    color: EdgeChartColors.darkGray,
                  },
                  {
                    label: dataLabels[3],
                    pointStyle: 'rectRot',
                    size: 1,
                    color: EdgeChartColors.lightGray,
                  },
                ];
                return labels.map((label, index) => ({
                  text: label.label,
                  pointStyle: label.pointStyle,
                  strokeStyle: label.color,
                  fillStyle: label.color,
                  lineWidth: label.size,
                  index,
                }));
              },
            },
          },
        },
      },
      plugins: [ChartDataLabels, barGrowthIndustry],
    });
    return benchmarkChart;
  },

  async_bench_mark_chart_dow_jones(element, jsonurl, dataLabels, ticks, colors, title) {
    asyncChart(jsonurl, (data) => {
      this.bench_mark_chart_dow_jones(element, data, dataLabels, ticks);
    });
  },

  projection_scenario_simulator(element, data, dataLabels, axisLabels) {
    Chart.defaults.scale.grid.display = true;
    Chart.defaults.scale.ticks.display = true;
    return new Chart(element, defaultLineChartConfiguration(data, dataLabels, axisLabels));
  },

};
